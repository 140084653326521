<template>
    <b-container class="py-5 text-left terms-and-conditions">
        <h1 class="mb-5">Términos y Condiciones</h1>
        <p>
            Los presentes términos y condiciones generales de uso (los "<span class="definition"
                >Términos y Condiciones</span
            >") son un contrato legal vinculante entre usted como usuario (el "<span
                class="definition"
                >Usuario</span
            >" ) y la sociedad Delivering Geniality, S.A.P.I. de C.V. ("<span class="definition"
                >Zubut</span
            >"), que rigen el uso de la aplicación denominada “Zubut” (la "<span class="definition"
                >Aplicación</span
            >") y del sitio de internet https://www.zubut.com (el "<span class="definition"
                >Sitio</span
            >"), así como de todos los contenidos, información, servicios y funciones disponibles en
            o a través de los mismos (los "<span class="definition">Contenidos</span>").
        </p>
        <p>
            <span class="definition">IMPORTANTE</span>: Favor de leer cuidadosamente y con detalle
            los presentes Términos y Condiciones antes de utilizar el Sitio, ya que, al hacer uso
            del Sitio y/o Aplicación de Zubut, así como a los Servicios, te adhieres automáticamente
            a los presentes Términos y Condiciones. En caso de no estar de acuerdo con los mismos,
            se sugiere al Usuario no hacer uso del Sitio y/o Aplicación de Zubut.
        </p>
        <h2 class="mt-5 mb-4">1. Disposiciones Generales.</h2>
        <p>
            1.1. Definiciones: Para efectos de claridad, las palabras o términos especificados a
            continuación tendrán los siguientes significados que se les atribuye y serán aplicables
            en los presentes Términos y Condiciones.
        </p>
        <ol>
            <li class="mb-3 ">
                <span class="definition pl-3 pl-3">Actualizaciones</span>: mejoras en funciones y
                versiones del software de la Aplicación y del Sitio.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Aplicación</span>: aplicación móvil de Zubut.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Contenidos</span>: son los servicios, funciones e
                información dentro del Sitio y/o Aplicación.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Orden de Servicio</span>: la orden que complete el
                Usuario en el Sitio y/o Aplicación que sea aceptada por un Transportista.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Paquete</span>: el bien objeto de los Servicios
                solicitados por el Usuario a través del Sitio y/o Aplicación de Zubut y enviado por
                un Transportista.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Sitio</span>: página web de Zubut, en la siguiente
                dirección: https://www.zubut.com
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Servicios</span>: servicios de mensajería y paquetería
                locales vía terrestre prestados por terceros proveedores de servicios de paquetería
                y transporte de mercancías por terceros proveedores independientes.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Tarifas</span>: el costo por los Servicios.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Transportistas</span>: son terceros proveedores
                independientes de Zubut, con recursos humanos y materiales propios.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Términos y Condiciones</span>: el presente documento
                en el que se establecen los términos y condiciones generales el acceso y/o uso de
                los Servicios que ofrece Zubut a través del Sitio y la Aplicación.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Usuarios</span>: cualquier persona que utilice y/o
                ingrese al Sitio y/o la Aplicación.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Zonas de Cobertura</span>: Zonas de Cobertura: el área
                geográfica donde se prestan los Servicios.
            </li>
            <li class="mb-3">
                <span class="definition pl-3">Zubut</span>: es la Sociedad Delivering Geniality,
                S.A.P.I. de C.V.
            </li>
        </ol>
        <p>
            1.2. Los Servicios podrán ser utilizados únicamente con fines lícitos. Todos los
            Contenidos que Zubut pone al alcance del Usuario a través de la Aplicación y/o el Sitio
            contienen elementos amparados por normas de propiedad intelectual, incluyendo protección
            del software, marcas, fotografías, logos, diseños, imágenes gráficas, música y sonido,
            sin que esta descripción implique limitación de naturaleza alguna.
        </p>
        <p>
            1.3. Los Servicios, así como los Contenidos sólo podrán ser utilizados por personas
            físicas mayores de edad, o por personas morales o jurídicas, por lo que al hacer uso del
            Sitio o la Aplicación, el Usuario manifiesta, (i) en caso de ser persona física, ser
            mayor de edad y contar con la capacidad suficiente para obligarse conforme a los
            presentes Términos y Condiciones; o (ii) en caso de representar a una persona moral o
            jurídica, contar con las facultades suficientes para obligarla conforme al presente
            documento y que el objeto social de la persona jurídica le permite contratar en los
            presentes Términos y Condiciones.
        </p>
        <p>
            1.4. Los padres o tutores de menores de edad serán responsables por los actos por ellos
            realizados según lo dispuesto por estos Términos y Condiciones, incluyendo los daños
            causados a terceros, acciones realizadas por ellos y que estén prohibidas por Ley y por
            las disposiciones de este acuerdo, sin perjuicio de la responsabilidad del Usuario.
        </p>
        <h2 class="mt-5 mb-4">2. Servicios.</h2>
        <p>
            2.1. Por medio de la Aplicación y del Sitio, Zubut ofrece a sus Usuarios una plataforma
            en línea, por medio de la cual los Usuarios pueden solicitar información, así como
            contratar los Servicios. Los Servicios será prestados por los Transportistas, siendo
            Zubut un mero intermediario entre el Usuario y el Transportista.
        </p>
        <h2 class="mt-5 mb-4">3. Cuenta del Usuario.</h2>
        <p>
            3.1. Para poder hacer uso de los Servicios disponibles en la Aplicación o en el Sitio,
            el Usuario deberá registrarse mediante la creación de una cuenta, para lo cual deberá
            ingresar la información que sea requerida para crear la cuenta, tales como datos de
            identificación, número telefónico, datos de tarjeta de crédito o débito, datos de
            facturación, entre otros, y creará una contraseña personal (password) la cual le servirá
            para ingresar en cualquier momento a dicha cuenta.
        </p>
        <p>
            3.2. El Usuario reconoce que la información proporcionada es verdadera, correcta, actual
            y completa, responsabilizándose civil y penalmente de dicha información, así como
            manifiesta haber leído, comprendido y aceptado el Aviso de Privacidad que se encuentra a
            su disposición en la Aplicación y en el Sitio.
        </p>
        <p>
            3.3. En caso dado de que la información del Usuario al momento de crear su cuenta
            resultare falsa, equivocada o incompleta, de tal modo que resulte imposible la
            comprobación e identificación del Usuario, Zubut tendrá el derecho de suspender o
            cancelar inmediatamente la cuenta del Usuario, sin necesidad de previo aviso,
            responsabilizándose en todo momento el Usuario por los daños finalmente ocasionados.
        </p>
        <p>
            3.4. La veracidad y autenticidad de los datos proporcionados por los Usuarios es
            requisito esencial para el mantenimiento del registro. No obstante, el uso de los
            Servicios parte de la buena fe entre las partes, en virtud de lo cual Zubut no tendrá la
            obligación de verificar la autenticidad y/o exactitud de la información proporcionada
            por el Usuario, aunque sí conservará el derecho, ejercible en cualquier momento de
            hacerlo.
        </p>
        <p>
            3.5. El Usuario podrá acceder y modificar o actualizar la información de su cuenta en
            cualquier momento.
        </p>
        <p>
            3.6. El Usuario es responsable de la protección de la confidencialidad de la contraseña
            de su cuenta en la Aplicación y en el Sitio. El Usuario autoriza expresamente a Zubut a
            mantener en su registro las informaciones proporcionadas por el Usuario, también
            autoriza a Zubut a proporcionar información sobre el referido registro a (i) autoridades
            que lo solicitaren conforme lo permitido por la legislación en vigor, (ii) los
            Transportistas, únicamente para la prestación de los mismos, y (iii) a sus asociados
            estratégicos, comerciales o técnicos con la finalidad de ofrecer mejores Servicios y/o
            Contenidos del Usuario, de conformidad con lo dispuesto en el Aviso de Privacidad.
            Además, el Usuario permite expresamente a Zubut recopilar información para la
            realización de seguimiento de tráfico, con intención de identificar grupos y perfiles de
            usuarios, así como para fines de orientación publicitaria. El Usuario será responsable
            por todas las operaciones efectuadas en su cuenta, pues el acceso a la misma está
            restringido al ingreso y uso de su contraseña personal, de conocimiento exclusivo del
            Usuario.
        </p>
        <p>
            3.7. En caso de robo o extravío de la contraseña personal del Usuario, éste se obliga a
            notificar dicha situación a Zubut por medio de su área de atención a usuarios de manera
            inmediata, en el entendido que el Usuario será el único responsable de los daños y
            perjuicios que se pudieran ocasionar por terceros mediante el uso de la cuenta del
            Usuario.
        </p>
        <p>
            3.8. El Usuario podrá cancelar su cuenta o desinstalar la Aplicación en cualquier
            momento, en el entendido que Zubut no será responsable de cualquier pérdida de
            información que pudiera sufrir durante dicho proceso. Asimismo, la cancelación de la
            cuenta o la desinstalación de la Aplicación no libera al Usuario de cualquier obligación
            o responsabilidad adquirida por éste con anterioridad.
        </p>
        <p>
            3.9. Asimismo, el Usuario reconoce que Zubut podrá en cualquier momento y sin necesidad
            de aviso previo, suspender o cancelar la cuenta del Usuario, en caso de que a criterio
            de Zubut, el Usuario haga uso de la misma en contravención a cualquier disposición de
            los presentes Términos y Condiciones, por lo que el Usuario libera a Zubut de cualquier
            responsabilidad derivada de dicha suspensión o cancelación.
        </p>
        <h2 class="mt-5 mb-4">4. Uso de los Servicios.</h2>
        <p>
            4.1. El Usuario registrado podrá acceder, por medio de su cuenta, a los diversos
            Contenidos, así como a los Servicios ofrecidos a través de la Aplicación o del Sitio,
            para efecto de lo cual deberá proporcionar información adicional que le sea requerida
            por Zubut, la cual podrá incluir de manera enunciativa más no limitativa, su
            localización o la del Paquete, así como el domicilio al que desea enviarlo, la forma de
            pago y una breve descripción del tipo de bienes que contendrá el Paquete, entre otros.
        </p>
        <p>
            4.2. Una vez ingresada la información requerida, la Aplicación o el Sitio generarán la
            Orden de Servicio en nombre del Usuario, la cual será enviada al Transportista
            pertinente, el cual podrá aceptar o rechazar dicha Orden de Servicio.
        </p>
        <p>
            4.3. Cada Orden de Servicio se considerará como una oferta económica por parte del
            Usuario respecto del servicio de paquetería o mensajería solicitado, en virtud de lo
            cual, se entenderá condicionada a la aceptación por parte del Transportista. La Orden de
            Servicio no se tendrá por otorgada sino hasta el momento en que Zubut confirme
            definitivamente la operación al Usuario a través de una comunicación electrónica por
            medio de la Aplicación o del Sitio.
        </p>
        <p>
            4.4. En el supuesto de que el Transportista rechace la Orden de Servicio, ésta podrá ser
            reenviada a otro(s) Transportista(s), o rechazada definitivamente, en cuyo caso le será
            notificada dicha situación al Usuario. En caso de que el Transportista acepte la Orden
            de Servicio, le será notificado al Usuario, así como la Tarifa y, en su caso, cualquier
            información adicional que resulte pertinente para la correcta prestación de dichos
            servicios por parte de los Transportistas.
        </p>
        <p>
            4.5. A la aceptación de la Orden de Servicio por parte del Transportista, éste y el
            Usuario se entenderán obligados por los presentes Términos y Condiciones, así como por
            lo establecido en el modelo de contrato de servicios que los Transportistas que aceptan
            Órdenes de Servicio a través de la Aplicación y del Sitio han convenido en usar a
            efecto.
        </p>
        <p>
            4.6. A efecto de que el Usuario esté en posibilidades de recibir los Servicios de
            mensajería o paquetería, Zubut podrá reenviar la Orden de Servicio a distintos
            Transportistas a efecto de que la misma pueda ser aceptada por alguno de ellos, en el
            entendido que Zubut no garantiza al Usuario que dicha Orden de Servicios sea aceptada
            por algún Transportista, toda vez que la aceptación o rechazo de dichas Órdenes de
            Servicio será a discreción de cada uno de los Transportistas.
        </p>
        <p>
            4.7. Por medio de la Aplicación el Usuario podrá dar seguimiento al estatus de sus
            envíos, así como, en su caso, de la ubicación de sus Paquetes una vez que hayan sido
            recolectados por el Transportista.
        </p>
        <p>
            4.8. En caso de que el Paquete no haya podido ser entregado a su destinatario por causas
            no imputables a Zubut o al Transportista, se le notificará al Usuario a través de su
            cuenta, en cuyo caso el Usuario podrá optar por la devolución del Paquete en el punto de
            recolección, o por un intento adicional de entrega, en el entendido que cada intento
            adicional será cobrado al Usuario conforme a la Tarifa vigente.
        </p>
        <p>
            4.9. El Usuario reconoce que los Servicios de Zubut se limitan al licenciamiento de la
            Aplicación y del Sitio, así como a la intermediación entre el Usuario y el
            Transportista, por lo que, una vez aceptada una Orden de Servicio por algún
            Transportista, será responsabilidad exclusiva de éste el prestar los Servicios en favor
            del Usuario, toda vez que Zubut no presta servicios de mensajería o paquetería, ni
            participa de manera activa en la prestación de los mismos.
        </p>
        <h2 class="mt-5 mb-4">5. Tarifas.</h2>
        <p>
            5.1. Los Servicios proporcionados por Zubut, así como el uso de la Aplicación y del
            Sitio no tendrán costo para el Usuario, sin embargo, Zubut se reserva el derecho a
            establecer un costo por dicho uso en cualquier momento, mediante simple aviso al Usuario
            por medio de la Aplicación o del Sitio.
        </p>
        <p>
            5.2. Las Tarifas por los Servicios serán establecidas tomando en consideración el tipo
            de servicio, los puntos de recolección y entrega, así como la distancia aproximada entre
            dichos puntos, los horarios, número de intentos de entrega, y cualquier otro elemento
            particular de los Servicios solicitados por el Usuario.
        </p>
        <p>
            5.3. Sin perjuicio de lo anterior el Usuario podrá consultar una Tarifa estimada por
            medio de la Aplicación o el Sitio, previo al envío de la Orden de Servicio, en el
            entendido que la misma puede variar al alza o a la baja según los factores antes
            mencionados.
        </p>
        <p>
            5.4. Las Tarifas podrán ser modificadas en cualquier momento, por lo que será
            responsabilidad del Usuario verificar las Tarifas vigentes previo al envío de cualquier
            Orden de Servicio.
        </p>
        <h2 class="mt-5 mb-4">6. Pagos y facturación.</h2>
        <p>
            6.1. El Usuario reconoce y acepta realizar el pago de los Servicios a Zubut, quien los
            recibirá por cuenta y en nombre del Transportista, por lo que, una vez aceptada una
            Orden de Servicio por parte de algún Transportista, el Usuario autoriza a Zubut a
            realizar el cobro de la Tarifa correspondiente, a la tarjeta de crédito o débito
            proporcionada por el Usuario, o mediante cualquier otra forma de pago admitida por la
            Aplicación o el Sitio.
        </p>
        <p>
            6.2. En caso de que realizada la entrega del Paquete solicitada por el Usuario no se
            realice el cobro total por la prestación de los Servicios por cualquier circunstancia,
            incluyendo sin limitar por saldo insuficiente en la tarjeta de crédito, débito o
            cualquier otra forma de pago, Zubut está facultado para hacer tantos intentos como sean
            necesarios para realizar el cargo de la Tarifa de forma exitosa, asimismo, el Usuario no
            podrá solicitar los Servicios ni hacer uso de la Aplicación o Sitio hasta que pague
            cualquier saldo o cantidad pendiente.
        </p>
        <p>
            6.3. Una vez confirmado el cobro exitoso por parte de Zubut, el Usuario podrá solicitar
            a través de su cuenta en la Aplicación o en el Sitio la factura electrónica
            correspondiente, así como la representación gráfica de la misma por los Servicios
            prestados, misma que será enviada por el Transportista a la cuenta de correo electrónico
            que haya sido proporcionada por el Usuario, a más tardar dentro de los cinco días
            hábiles siguientes a la fecha en que la haya solicitado.
        </p>
        <p>
            6.4. Será responsabilidad del Usuario verificar que los datos de facturación así como la
            cuenta de correo electrónico proporcionados sean correctos previo a la solicitud de la
            factura, toda vez que Zubut no se responsabiliza por facturas emitidas con datos
            erróneos o enviadas a correos electrónicos incorrectos, por causas imputables al
            Usuario, en el entendido que por ningún motivo se reexpedirán, reenviarán o corregirán
            facturas cuando éstas hayan sido emitidas o enviadas erróneamente por causas imputables
            al Usuario.
        </p>
        <p>
            6.5. De igual manera, será responsabilidad del Usuario contar con fondos suficientes
            para el pago de la Tarifa en la tarjeta de crédito o débito proporcionada o en el medio
            de pago acordado, por lo que en caso de que no sea posible realizar el cobro de la
            Tarifa correspondiente, la Orden de Servicio podrá ser cancelada de manera inmediata,
            siendo responsable el Usuario por cualquier daño o perjuicio ocasionado al Transportista
            y/o a Zubut por dicha cancelación.
        </p>
        <p>
            6.6. El Usuario reconoce que Zubut únicamente presta servicios de intermediación y
            recepción de pagos por cuenta de los Transportistas, por lo que cualquier aclaración o
            reclamación relacionada con dichos pagos deberá ser presentada directamente al
            Transportista, siempre y cuando la aclaración o reclamación no tenga que ver con el
            Sitio y/o Aplicación, o el procesador de pagos.
        </p>
        <h2 class="mt-5 mb-4">7. Zonas de Cobertura.</h2>
        <p>
            7.1. Los Servicios únicamente estarán disponibles en las Zonas de Cobertura, en el
            entendido que los Transportistas únicamente podrán prestar los Servicios siempre y
            cuando el punto de recolección y el de entrega se encuentren dentro de una misma Zona de
            Cobertura, o en Zonas de Cobertura contiguas, siempre que el tipo de Servicio lo
            permita.
        </p>
        <p>
            7.2. Zubut se reserva el derecho de modificar las Zonas de Cobertura en cualquier
            momento, sin necesidad de aviso previo y mediante su simple actualización en la
            Aplicación y en el Sitio.
        </p>
        <p>
            7.3. Por lo anterior, el Usuario deberá verificar que el Servicio que pretende solicitar
            se encuentra dentro de alguna Zona de Cobertura previo a su solicitud.
        </p>
        <p>
            7.4. En cualquier caso, la Zona de Cobertura se limitará a áreas urbanas y no incluirá
            el transporte inter-ciudades.
        </p>
        <h2 class="mt-5 mb-4">8. Paquetes.</h2>
        <p>
            8.1. El Usuario únicamente podrá hacer uso de los Servicios para enviar Paquetes que
            contengan bienes permitidos por la legislación mexicana, así como de los expresamente
            señalados en la Aplicación o en el Sitio, por lo que el Usuario será el único
            responsable de la veracidad de la información proporcionada a Zubut en la Orden de
            Servicio, así como del contenido de los Paquetes enviados. En ningún caso el Paquete
            podrá tener un peso superior a 20 (veinte) kilogramos, ni exceder sus dimensiones de
            50cm x 50cm x 50cm en caso de que el Transportiste realice la entrega en motocicleta, y
            deberá entregarse debidamente envuelto y rotulado o con embalaje que permita su manejo,
            reparto y entrega a cargo del Transportista, desde su origen hasta su destino final.
        </p>
        <p>
            8.2. De igual manera, será responsabilidad del Usuario entregar al Transportista el
            Paquete debidamente sellado o empacado de tal manera que el contenido no pueda sufrir
            daños o menoscabos durante su transportación con un manejo no especializado. El
            Transportista podrá negarse a recibir o transportar Paquetes que no reúnan las
            características anteriores o que a su juicio requieran de un manejo especializado, en
            cuyo caso no habrá lugar a reembolso alguno al Usuario.
        </p>
        <p>
            8.3. Asimismo, el Usuario deberá observar las políticas de valor máximo permitido y
            límites de responsabilidad, aplicables al contenido de los Paquetes, los cuales se
            encuentran disponibles en el apartado 15 de los Términos y Condiciones.
        </p>
        <p>
            8.4. En virtud de lo anterior, el Usuario se obliga a sacar en paz y a salvo a Zubut,
            sus afiliadas, funcionarios, empleados y contratistas, así como a los Transportistas,
            sus funcionarios, empleados y contratistas, de cualquier procedimiento judicial o
            administrativo que se llegare a instaurar en su contra por cualquier autoridad o
            tercero, derivado de cualquier incumplimiento a sus obligaciones conforme al presente
            apartado, obligándose de igual manera a indemnizar a la partes mencionadas por los daños
            y perjuicios que se les pudiera ocasionar como consecuencia de dicho procedimiento,
            incluyendo sin limitar, honorarios de abogados y demás costas judiciales.
        </p>
        <p>
            8.5. Los Transportistas podrán, mas no tendrán la obligación de, inspeccionar los
            Paquetes en cualquier momento, así como permitir a las autoridades competentes que así
            lo requieran, a llevar a cabo las inspecciones que consideren pertinentes. Asimismo,
            Zubut y/o los Transportistas se reservan el derecho a notificar a las autoridades
            correspondientes en caso de detectar algún incumplimiento a lo dispuesto en el presente
            apartado, que a su juicio pudiera constituir una violación a la legislación aplicable o
            a derechos de terceros.
        </p>
        <h2 class="mt-5 mb-4">9. Contenidos.</h2>
        <p>
            9.1. Los Contenidos mostrados en la Aplicación y en el Sitio podrán ser propios o de
            terceros, y podrán incluir todo tipo de archivos tales como textos, imágenes, sonidos y
            videos, así como enlaces (links) a otros sitios o aplicaciones ajenos a Zubut.
        </p>
        <p>
            9.2. El acceso a cualquier Contenido incluyendo los enlaces mostrados en la Aplicación o
            en el Sitio son opcionales, y serán bajo el riesgo y responsabilidad exclusiva del
            Usuario, quien reconoce que tiene en todo momento absoluta discrecionalidad de activar o
            no dichos enlaces.
        </p>
        <p>
            9.3. Por lo anterior y toda vez que Zubut no tiene control sobre los Contenidos de
            terceros, incluidos los sitios o aplicaciones a que lleven los enlaces, Zubut no será
            responsable por los contenidos, materiales, acciones y/o servicios prestados por los
            mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean
            causadas directa o indirectamente. La presencia de enlaces a otros sitios web no implica
            una sociedad, relación, aprobación, patrocinio o respaldo de Zubut a dichos sitios y sus
            contenidos.
        </p>
        <p>
            9.4. Los Contenidos disponibles en la Aplicación o el Sitio estarán sujetos a la
            disponibilidad o vigencia de los mismos, toda vez que se ofrecen sobre una base “tal
            cual” y “según sean disponibles”. Zubut no garantiza que los Contenidos o los Servicios
            satisfagan los requerimientos del Usuario o que los mismos no sufran interrupciones,
            sean seguros o estén exentos de errores, malware o virus, por lo que Zubut se reserva el
            derecho para suspender o adicionar cualquier Contenido en cualquier momento.
        </p>
        <p>
            9.5. Queda estrictamente prohibido cualquier tipo de copia, distribución, transmisión,
            retransmisión, publicación, impresión, difusión y/o explotación comercial de los
            Contenidos puestos a disposición del Usuario a través de la Aplicación y del Sitio, sin
            el previo consentimiento expreso y por escrito de Zubut o, en su caso, del titular de
            los derechos de propiedad intelectual correspondientes.
        </p>
        <p>
            9.6. El incumplimiento de lo aquí mencionado sujetará al infractor a todos los reclamos
            civiles y sanciones penales que pudieran corresponder conforme a la legislación
            aplicable, independientemente de la cancelación de la cuenta del Usuario infractor.
        </p>
        <h2 class="mt-5 mb-4">10. Acceso y uso de la Aplicación y del Sitio.</h2>
        <p>
            10.1. Se encuentra estrictamente prohibido al Usuario violar o intentar violar la
            seguridad de la Aplicación o del Sitio, incluyendo, sin limitación: acceder a datos no
            destinados al Usuario o iniciar sesión en un servidor o cuenta en la que el Usuario no
            esté autorizado a ingresar, intentar interferir con el Servicio a cualquier otro
            usuario, huésped o red, incluyendo, sin limitación, a través del envío de virus al
            Sitio, sobrecarga, inundación, spam, enviar correo no solicitado, incluyendo promociones
            y/o publicidad de productos o servicios, falsificar cualquier encabezado de TCP/IP o
            cualquier parte de la información del encabezado en cualquier correo electrónico o grupo
            de noticias, así como la utilización de robots, arañas, orugas, scraper, o cualquier
            otro medio automatizado para acceder o utilizar los Servicios.
        </p>
        <p>
            10.2. El Usuario se obliga a no utilizar ningún dispositivo, software o rutina para
            interferir o intentar interferir con el uso correcto de la Aplicación y/o el Sitio o
            cualquier actividad llevada a cabo en los mismos.
        </p>
        <p>
            10.3. Cada Usuario será responsable del equipo o dispositivos y servicios de telefonía e
            internet que pudiera requerir para el perfecto acceso al uso de la Aplicación y el
            Sitio. El Usuario será el único responsable de eventuales daños que su equipo pudiera
            sufrir debido al mal uso de cualquier hardware, software o conexiones, así como de la
            compatibilidad con la Aplicación o el Sitio.
        </p>
        <p>
            10.4. Asimismo, el Usuario reconoce que la utilización de la Aplicación o del Sitio
            pueden generar costos adicionales por el uso de datos móviles o internet proporcionados
            por la compañía de telefonía o internet con la que el Usuario tenga contratados dichos
            servicios, por lo que Zubut no será responsable de tales costos, ni del servicio que
            proporcione dicha compañía en favor del Usuario.
        </p>
        <p>
            10.5. Cualquier violación al sistema o red de seguridad de la Aplicación o del Sitio
            podrá dar lugar a la cancelación de la cuenta del Usuario, así como al inicio de
            cualquier procedimiento de responsabilidad civil o penal en contra del Usuario.
        </p>
        <p>
            10.6. El Usuario reconoce que podrían presentarse (i) interrupciones en la prestación de
            los Servicios o las Actualizaciones, o, incluso, (ii) otros hechos ocurridos por causas
            fuera de control de Zubut. Zubut no podrá ser responsabilizado de cualesquiera datos
            perdidos durante la transmisión de información por medio de Internet.
        </p>
        <p>
            10.7. Zubut está exento de cualquier responsabilidad que ocurra por interrupciones o
            suspensiones del servicio de acceso a Internet ocasionadas por la falla en el sistema de
            telecomunicaciones, en el suministro de energía eléctrica, casos fortuitos o de fuerza
            mayor o una acción de terceros que puedan inhabilitar los equipos que suministran el
            acceso a la red.
        </p>
        <p>
            10.8. Por lo anterior, Zubut no se responsabiliza por cualquier daño, perjuicio o
            pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet.
            Zubut tampoco será responsable por cualquier virus que pudiera infectar el equipo del
            Usuario como consecuencia del acceso, uso o examen de la Aplicación o del Sitio o a raíz
            de cualquier transferencia de datos, archivos, imágenes, textos, audio o videos
            contenidos en el mismo. Los Usuarios no podrán imputarle a Zubut responsabilidad alguna
            ni exigir pago de daños o perjuicios, en virtud de dificultades técnicas o fallas en los
            sistemas o en Internet. Zubut no garantiza el acceso y uso continuado o ininterrumpido
            de los Servicios.
        </p>
        <p>
            10.9. La Aplicación, el Sitio o cualquier Actualización a los mismos pueden
            eventualmente no estar disponibles debido a dificultades técnicas o fallas de Internet,
            o por cualquier otra circunstancia ajena a Zubut; en tales casos se procurará
            restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún
            tipo de responsabilidad. Zubut no será responsable por ningún error u omisión contenidos
            en la Aplicación o el Sitio.
        </p>
        <p>
            10.10. Zubut podrá, en cualquier momento y a su exclusivo criterio, descontinuar,
            suspender, terminar o alterar la forma de acceso a la Aplicación o al Sitio con relación
            a cualquier contenido, periodo de disponibilidad, Actualización y equipamiento
            necesarios para el acceso y/o utilización de los mismos. Zubut se reserva el derecho de
            descontinuar la diseminación de cualquier información, alterar o eliminar métodos de
            transmisión, alterar la velocidad de transmisión de datos o cualesquiera otras
            características de señal.
        </p>
        <h2 class="mt-5 mb-4">11. Licencia de Uso.</h2>
        <p>
            11.1. Por medio de este documento y sujeto al cumplimiento de los presentes Términos y
            Condiciones, Zubut otorga en favor del Usuario una licencia personal limitada, no
            exclusiva, no transferible, no sublicenciable, revocable, por plazo indeterminado, para
            (i) descargar, instalar y utilizar la Aplicación en su dispositivo móvil, o para acceder
            a los Servicios por medio del Sitio, y (ii) para utilizar los Servicios; en ambos casos
            dicha licencia será para uso personal del Usuario y en ningún caso podrá ser
            sublicenciada, revendida o de cualquier otra forma explotada sin el consentimiento
            previo y por escrito de Zubut.
        </p>
        <p>
            11.2. Cualquier uso no autorizado de la Aplicación o del Sitio o en contravención a los
            presentes Términos y condiciones dará lugar a la terminación inmediata y sin necesidad
            de resolución judicial de la licencia otorgada en favor del Usuario conforme al párrafo
            anterior.
        </p>
        <h2 class="mt-5 mb-4">12. Propiedad intelectual.</h2>
        <p>
            12.1. El software asociado a la Aplicación y al Sitio, así como todos los Contenidos
            disponibles en los mismos, incluyendo los enlaces, son, para efectos del presente
            documento, propiedad exclusiva de Zubut, y están protegidas por las leyes y los tratados
            internacionales en materia de derechos de autor, marcas, patentes, modelos y diseños
            industriales aplicables en México.
        </p>
        <p>
            12.2. Queda prohibida la copia, distribución, transmisión, publicación, conexión,
            ingeniería inversa o cualquier otro tipo de modificación de la Aplicación, el Sitio o de
            los Contenidos sin expresa y previa autorización de Zubut o, en su caso, del titular de
            los derechos de propiedad correspondientes.
        </p>
        <p>
            12.3. Asimismo, se encuentra prohibido al Usuario sublicenciar, vender, revender,
            transferir, ceder o de cualquier forma explotar o poner a disposición de cualquier
            tercero la Aplicación o el Sitio, así como crear vínculos en internet hacia o desde la
            Aplicación o el Sitio, o reproducirlos en sitios “espejo”, sin la autorización previa y
            por escrito de Zubut.
        </p>
        <p>
            12.4. Cualquier violación a lo dispuesto en este apartado constituirá una infracción a
            los derechos de propiedad intelectual y sujetará al Usuario a las sanciones
            administrativas, civiles y penales que resulten aplicables.
        </p>
        <p>
            12.5. Zubut se reserva todos los derechos no expresamente otorgados bajo el presente
            documento. El Usuario declara y reconoce que la descarga de cualquier contenido de la
            Aplicación o del Sitio no le confiere la propiedad sobre el mismo.
        </p>
        <p>
            12.6. Cualesquiera marcas exhibidas en la Aplicación, el Sitio o en cualquier enlace no
            deben ser consideradas como de dominio público y son propiedad exclusiva de los terceros
            contratantes de Zubut.
        </p>
        <p>
            12.7. Zubut no tiene el deber o la responsabilidad de proporcionar al Usuario
            cualesquiera indicaciones que auxilien a la identificación del contenido como protegido
            por derechos de propiedad intelectual. El Usuario será el único responsable de
            cualesquiera daños a terceros que resultaren de la violación de derechos de propiedad
            intelectual, en virtud de la utilización no autorizada del mencionado Contenido.
        </p>
        <p>
            12.8. Ninguna disposición del presente documento deberá interpretarse como una venta,
            cesión, transmisión, licencia exclusiva ni cualquier otro tipo de transferencia en favor
            del Usuario respecto de los derechos de propiedad intelectual de Zubut.
        </p>
        <h2 class="mt-5 mb-4">13. Actualizaciones.</h2>
        <p>
            13.1. Zubut podrá, en cualquier momento, realizar modificaciones, adiciones, mejoras o
            Actualizaciones a la Aplicación o al Sitio, incluyendo sin limitar, a los Contenidos.
            Dichas Actualizaciones tienen como objetivo mejorar los Servicios en beneficio del
            Usuario, así como mostrar Contenidos acordes y actualizados con los intereses de los
            Usuarios en todo momento, o, en su caso, reparar alguna falla que pudiera presentar la
            Aplicación o el Sitio.
        </p>
        <p>
            13.2. Cuando exista alguna Actualización disponible para el dispositivo del Usuario,
            Zubut podrá enviar una notificación al Usuario por medio de la Aplicación, el Sitio,
            correo electrónico, mensaje de texto, o por cualquier otro medio de comunicación
            proporcionado por el Usuario.
        </p>
        <p>
            13.3. Zubut se reserva el derecho de establecer alguna contraprestación a cargo del
            Usuario por la descarga e instalación de cualquier Actualización.
        </p>
        <p>
            13.4. La descarga e instalación de las Actualizaciones son opcionales para el Usuario,
            en el entendido que algunas Actualizaciones pueden resultar indispensables para la
            continuidad de la prestación de los Servicios en su favor, por lo que en caso de que el
            Usuario se abstenga de descargar e instalar alguna Actualización, la Aplicación o el
            Sitio podrían dejar de funcionar en su equipo. Zubut no será responsable de cualquier
            daño o pérdida que pudiere ocasionarse al Usuario como consecuencia de la omisión en la
            descarga e instalación de cualquier Actualización.
        </p>
        <p>
            13.5. Los presentes Términos y Condiciones serán aplicables para cualquier Actualización
            de la Aplicación o del Sitio, salvo estipulación en contrario, por lo que en caso de que
            el Usuario descargue instale cualquier Actualización se entenderá que acepta los mismos.
        </p>
        <h2 class="mt-5 mb-4">14. Privacidad.</h2>
        <p>
            14.1. Zubut implementará todas las medidas posibles para mantener la confidencialidad y
            la seguridad de la información del Usuario, pero no responderá por los daños o
            perjuicios que pudieran derivarse de la violación de esas medidas por parte de terceros
            que utilicen las redes públicas o Internet, alterando los sistemas de seguridad para
            obtener acceso a la información de los Usuarios.
        </p>
        <p>
            14.2. El Usuario será el único responsable de cualquier información o datos que
            proporcione a Zubut, tales como datos de contacto, nombre, domicilio y números
            telefónicos entre otros del destinatario, por lo que el Usuario declara que tiene el
            consentimiento del destinatario para proporcionar dicha información a Zubut.
        </p>
        <p>
            14.3. Dicha información podrá ser usada por Zubut para fines publicitario, para
            facilitar la entrega de los Paquetes, para notificar la entrega del paquete, para que el
            Transportista se ponga en contacto con el destinatario para ubicar el punto de entrega y
            para enviar información y promociones referentes a los servicios Zubut, lo anterior a
            través del envío de mensajes de texto, correos electrónicos o cualquier otra
            comunicación electrónica.
        </p>
        <p>
            14.4. Sin perjuicio de lo anterior, el uso de la Aplicación y del Sitio se encuentran
            condicionados a la lectura y aceptación por parte del Usuario de la Política de
            Privacidad, la cual se encuentra a su disposición tanto en la Aplicación como en el
            Sitio, por lo que en caso de que el Usuario continúe utilizando la Aplicación o el
            Sitio, el Usuario manifiesta su conformidad con dicha Política de Privacidad.
        </p>
        <h2 class="mt-5 mb-4">15. Límites de Responsabilidad y Garantías.</h2>
        <p>
            15.1. El Usuario comprende y está de acuerdo en que el acceso y utilización de la
            Aplicación y el Sitio es realizado de conformidad con su criterio exclusivo y bajo su
            propio riesgo y que será el único responsable de cualesquiera daños causados a su
            sistema de computación o a su dispositivo móvil, intercepción o pérdida de datos
            resultante de la utilización de los Servicios o de la descarga (download) de materiales
            o datos contenidos en la Aplicación, el Sitio o en los enlaces.
        </p>
        <p>
            15.2. Bajo ninguna circunstancia Zubut, sus empleados, contratantes, directores o
            agentes serán responsables de cualquier daño, ya sea directo o indirecto, que resulte
            del uso, o uso inapropiado de la Aplicación o del Sitio, incluyendo pero no limitándose
            al uso de los Contenidos disponibles a través de los mismos.
        </p>
        <p>
            15.3. Asimismo, toda vez que los Servicios proporcionados por Zubut se limitan a la
            intermediación y recepción pasiva de pagos por cuenta de los Transportistas a través de
            la Aplicación y del Sitio, el Usuario reconoce que la calidad de los servicios de
            paquetería y mensajería será enteramente responsabilidad de los Transportistas, quienes
            son personas independientes a Zubut, y quienes prestarán en última instancia dichos
            servicios en favor del Usuario. Cualquier reclamación que el Usuario pretenda hacer
            valer en relación con los Servicios, deberá ser remitida directamente al Transportista.
        </p>
        <p>
            15.4. En virtud de lo anterior, el Usuario está de acuerdo en sacar en paz y a salvo a
            Zubut, sus empresas afiliadas, sus socios, funcionarios, empleados, comisionistas,
            terceros contratantes y asociados por cualesquiera reclamaciones, procesos, acciones,
            así como cualesquiera otras responsabilidades (inclusive honorarios jurídicos) derivados
            del uso de los servicios de mensajería o paquetería prestados por los Transportistas, de
            la utilización indebida de los Servicios, de la violación de éstos Términos y
            Condiciones, la violación de los derechos de cualquier otra persona o entidad incluyendo
            sin limitar de los Transportistas, o cualquier violación de las declaraciones, garantías
            y acuerdos hechos aquí por el Usuario; así como indemnizarlos por los daños y perjuicios
            que correspondieren.
        </p>
        <p>
            15.5. Estas limitaciones también se aplicarán con respecto a las pérdidas, daños y
            perjuicios sufridos por el Usuario o cualquier tercero en relación con cualesquiera
            contenidos, productos, servicios, ofertas o promociones vendidas o abastecidas por
            terceros, y no por Zubut, a través de los sitios o enlaces mostrados en la Aplicación o
            en el Sitio.
        </p>
        <p>
            15.6. Sin perjuicio de lo anterior, en cualquier otro caso, y en la medida en que la
            legislación aplicable lo permita, el Usuario acepta que cualquier responsabilidad de
            Zubut derivada de sus Servicios estará limitada únicamente al monto que haya sido pagado
            por el Usuario a Zubut por el servicio proporcionado por el Transportista del que se
            derive la reclamación.
        </p>
        <h2 class="mt-5 mb-4">16. Cookies.</h2>
        <p>
            16.1. El Usuario que utilice los Servicios, conviene en recibir Cookies que les
            transmitan los servidores de Zubut. Por ello, Zubut pone a disposición del Usuario en el
            Sitio y Aplicación la Política de Cookies de Zubut.
        </p>
        <h2 class="mt-5 mb-4">17. Términos y Condiciones Particulares.</h2>
        <p>
            17.1. A los Servicios les serán aplicables en lo general los presentes Términos y
            Condiciones, sin embargo, a cada Servicio le podrán ser aplicables términos y
            condiciones particulares (los “Términos y Condiciones Particulares”), mismos que se
            encontrarán a disposición del Usuario a través de la Aplicación o del Sitio.
        </p>
        <p>
            17.2. En caso de que alguna disposición contenida en los presentes Términos y
            Condiciones contravenga lo establecido en los Términos y Condiciones Particulares,
            prevalecerá lo dispuesto en éstos últimos, sin perjuicio de que sean aplicables en lo
            general las demás disposiciones del presente documento.
        </p>
        <h2 class="mt-5 mb-4">18. Modificaciones.</h2>
        <p>
            18.1. El presente documento constituye el acuerdo integral entre Zubut y el Usuario
            relacionado con los asuntos contenidos en el mismo. Zubut se reserva el derecho de, a su
            exclusivo criterio, adicionar, enmendar o de cualquier otra forma modificar el presente
            documento sin necesidad de previo aviso, mediante su publicación en la Aplicación o en
            el Sitio. Por lo anterior, es recomendable que el Usuario vuelva a leer con regularidad
            este documento, de forma que se mantenga siempre informado sobre eventuales
            modificaciones.
        </p>
        <p>
            18.2. Si hubiese cualquier modificación al presente documento y el Usuario continuará
            utilizando los Servicios, se entenderá que el Usuario ha leído y aceptado los nuevos
            Términos y Condiciones.
        </p>
        <h2 class="mt-5 mb-4">19. Cancelaciones y reembolsos.</h2>
        <p>
            19.1. En caso de que por causas directamente imputables a Zubut o a los Transportistas,
            algún Paquete no sea entregado a su destino dentro del plazo convenido, el Usuario podrá
            solicitar la devolución de la Tarifa que hubiere pagado por dichos Servicios, en el
            entendido que por ningún motivo serán consideradas causas imputables a Zubut o a los
            Transportistas, la falta de entrega de los Paquetes por ausencia o dificultad para
            localizar al destinatario, falta de capacidad o negativa por parte del destinatario para
            recibir el Paquete, por falta de señalización o localización del domicilio para la
            recolección o entrega, por datos erróneos o incompletos proporcionados por el Usuario en
            la Orden de Servicio, o por cualquier otra causa ajena a Zubut o al Transportista.
        </p>
        <p>
            19.2. Una vez generada y enviada la Orden de Servicio por el Usuario, tendrá un lapso de
            5 (cinco) minutos para cancelar la misma sin que se genere costo alguno, si el Usuario
            decide cancelar la Orden de Servicio después del lapso señalado se le cobrará la Tarifa
            mínima publicada en el Sitio o Aplicación.
        </p>
        <p>
            19.3. Una vez confirmada una Orden de Servicio por el Usuario y recolectado el Paquete,
            no habrá lugar a reembolso de cantidad alguna por cancelaciones o cambios en los
            servicios solicitados, por causas ajenas a Zubut.
        </p>
        <h2 class="mt-5 mb-4">20. Causas de fuerza mayor.</h2>
        <p>
            20.1. Ninguna de las partes será responsable por cualquier incumplimiento o retraso en
            el cumplimiento de sus obligaciones bajo el presente documento, si dicho incumplimiento
            deriva de un caso fortuito o evento de fuerza mayor que impida a cualquiera de las
            partes el cumplimiento, total o parcial, de sus obligaciones.
        </p>
        <h2 class="mt-5 mb-4">21. Vigencia.</h2>
        <p>
            21.1. La vigencia del presente acuerdo comenzará en la fecha en que el Usuario instale
            la Aplicación en su dispositivo móvil, o de cualquier forma comience a hacer uso de los
            Servicios por medio de la Aplicación o del Sitio, y continuará vigente durante todo el
            tiempo en que haga uso de los mismos, pudiendo el Usuario terminarlo en cualquier
            momento sin necesidad de aviso previo, a efecto de lo cual deberá (i) saldar cualquier
            adeudo pendiente de pago; (ii) cancelar su cuenta y desinstalar la Aplicación, en su
            caso; y (iii) abstenerse de hacer uso de los Servicios.
        </p>
        <p>
            21.2. Por su parte, Zubut se reserva el derecho a dar por terminado el presente
            documento, así como a cesar la prestación de los Servicios en cualquier momento, sin
            necesidad de aviso previo.
        </p>
        <h2 class="mt-5 mb-4">22. Disposiciones Finales.</h2>
        <p>
            22.1. El Usuario acepta que, en adición a los presentes Términos y Condiciones, el uso
            de la Aplicación se encuentra sujeto a los términos y condiciones aplicables para la
            plataforma o servidores del Dispositivo Móvil del Usuario (vg. App Store, Google Play,
            etc.).
        </p>
        <p>
            22.2. Ningún Usuario podrá ceder o transferir en favor de ningún tercero sus derechos y
            obligaciones conforme al presente, sin autorización previa y por escrito de Zubut. Zubut
            podrá ceder libremente sus derechos y obligaciones a cualquier tercero.
        </p>
        <p>
            22.3. Para todo lo relacionado con el cumplimiento y la interpretación de los presentes
            Términos y Condiciones, las partes se someten a la legislación aplicable, así como a la
            jurisdicción de los tribunales competentes de Guadalajara, Jalisco, renunciando en este
            acto a cualquier fuero que por razón de su domicilio presente o futuro pudiera
            corresponderles.
        </p>
        <p>
            22.4. El Usuario en este acto, acepta que ha leído detenidamente y comprende el
            contenido y alcance de todas y cada una de las disposiciones contenidas en los Términos
            y Condiciones y en la Política de Privacidad.
        </p>
        <p>
            22.5. Para mayor información podrá contactar al área de atención a usuarios de Zubut en
            la dirección help@zubut.com.
        </p>
        <p></p>
    </b-container>
</template>

<script>
export default {
    name: "TermsAndConditions",
    metaInfo: {
        title: "Términos y Condicones",
        meta: [
            {
                name: "description",
                content: `Términos y Condiciones de Zubut`
            }
        ]
    }
};
</script>

<style scoped lang="scss">
.terms-and-conditions {
    .definition {
        text-decoration: underline;
        font-weight: bold;
        font-style: italic;
    }

    ol {
        list-style-type: lower-alpha;

        li::marker {
            font-weight: bold;
        }
    }
}
</style>
